.valign-profile {
  color: #707070 !important;

  .card-profile {
    padding: 40px 33.5px 60px;

    & h2 {
      font-size: 23px;
      color: #707070;
      font-weight: 400;
    }

    & h5 {
      font-size: 11px;
      margin-bottom: 20px;
    }

    & button {
      width: 220px;
      /* height: 40px;
      font-weight: 500;
      text-transform: none;
      font-size: 20px;
      line-height: 1.5rem; */
    }

    .profile-cards {
      width: 135px;
      height: 140px;
      border-radius: 0;
      margin-bottom: 35px;
      border: 2px solid #FFF;
      cursor: pointer;

      & img {
        /* width: 60px;
        height: 78px; */
        max-height: 60px;
        height: 100%;
      }

      .logo-caracol{
        height: 60px !important;
      }

      & .img-fluid {
        width: 100%;
        height: auto;
      }

      & span {
        font-size: 12px;
        font-weight: 500;

      }

      .font-size-10 {
        font-size: 10px;
      }
    }
  }
}

.selected {
  border: 2px solid #163264 !important;
}

.testandoCards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 500px;
}